/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 30;
	width: 100%;
	height: 105px;
	padding: 31px 0 12px;
	border-top: 8px solid $color-blue;
	border-bottom: 1px solid $color-light;
	background: $color-light;
	transition: box-shadow .4s;

	.container {
	  max-width: 1220px;
	  width: 100%;
	}

	.header {

		&__body {

			.logo {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&--secondary {
		border-bottom-color: #dddddd;
	}

	&.fixed {
		box-shadow: 0 1px 5px rgba(0,0,0,0.15);
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.header {

		.header {

			&__body {

				.logo {
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.header {
		height: 118px;
		padding: 15px 0 5px;
		text-align: center;

		.header {

			&__body {

				.logo {
					position: static;
					width: 147px;
					height: 34px;
					margin: 0 0 10px;
					transform: translate(0);
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.header {
		height: 87px;
		padding: 25px 0 14px;
		text-align: left;

		.header {

			&__body {

				.logo {
					width: 134px;
					height: 31px;
					margin: 0 0 0 -6px;
				}
			}
		}
	}
}
