/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$shell-max-width:                   1200px;
$shell-gutter:                      10px;


$font-family-serif:                 'Times', serif;
$font-montserrat: 					'Montserrat', sans-serif;


$color-dark: 						#000000;
$color-light: 						#ffffff;
$color-blue: 						#093e52;
$color-lightblue: 					#00505d;
$color-orange: 						#bd6122;
$color-grey: 						#888888;
$color-darkgrey: 					#999999;
$color-lightgrey: 					#f5f5f5;


$breakpoint-large-desktop:			1399px;
$breakpoint-small-desktop:			1199px;
$breakpoint-tablet-portrait:		991px;
$breakpoint-mobile: 				767px;
$breakpoint-mobile-portrait: 		374px;


/*  Bootstrap Variables  */

$font-size-base: 					17px;
$line-height-base:      			1.59;

$grid-gutter-width: 30px !default;
$container-large-desktop: (1170px + $grid-gutter-width) !default;

.ta-left {
  text-align: left;
}