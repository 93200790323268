/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {

	form {
		display: flex;
	}

	.gform_heading {
		display: none;
	}

	.gform_fields {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		list-style: none outside none;
	}

	.gfield {
		flex: 0 0 33.33%;
		max-width: 33.33%;
		padding: 0 15px;
	}

	.gfield_label {
		display: none;
	}

	.ginput_container {

		input {
			display: block;
			width: 100%;
			height: 50px;
			padding: 0 19px;
			border: none;
			border-radius: 0;
			background: $color-lightgrey;
			font-size: 14px;
			line-height: 50px;
			color: $color-grey;
			appearance: none;
		}
	}

	.gform_body {
		flex: 0 1 100%;
	}

	.gform_footer {
		flex: 0 0 auto;
		margin-left: 23px;
	}

	.gform_button {
		display: block;	
		min-width: 138px;
		height: 50px;
		padding: 0 5px;
		border: 2px solid $color-orange;
		border-radius: 0;
		background: $color-orange;
		font-size: 12px;
		line-height: 48px;
		color: $color-light;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		appearance: none;
		transition: border .4s, background .4s, color .4s;

		&:hover,
		&:focus {
			border-color: $color-orange;
			background: $color-light;
			color: $color-orange;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.subscribe {

		.gform_fields {
			margin: 0 -10px;
		}

		.gfield {
			padding: 0 10px;
		}

		.gform_footer {
			margin-left: 20px;
		}

		.gform_button {
			min-width: 150px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.subscribe {

		form {
			display: block;
		}

		.gform_fields {
			display: block;
		}

		.gfield {
			max-width: none;
			margin-bottom: 18px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ginput_container {

			input {
				padding: 0 15px;
			}
		}

		.gform_footer {
			padding-top: 13px;
			margin-left: 0;
		}

		.gform_button {
			display: block;
			width: 100%;
			min-width: 0;
		}
	}
}
