/* ------------------------------------------------------------ *\
	News Items
\* ------------------------------------------------------------ */

.news-items {

	&:last-child {
		margin-bottom: -63px;
	}

	.row {
		overflow: hidden;

		&:before,
		&:after {
			display: none;
		}

		[class^="col-"] {

			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}

	&--reports {

		&:last-child {
			margin-bottom: -88px;
		}

		.news-item {
			margin-bottom: 88px;
		}
	}
}

.news-item {
	margin-bottom: 63px;
	text-align: center;

	.news {

		&__image {
			overflow: hidden;
			margin-bottom: 35px;

			img {
				width: 100%;
				transition: transform .6s;
			}

			a {

				&:hover {

					img {
						transform: scale(1.1);
					}
				}
			}
		}

		&__content {
			padding: 0 29px;

			> small {
				display: block;
				margin-bottom: 9px;
				font-size: 12px;
				color: $color-grey;
				font-weight: normal;
				letter-spacing: 0.05em;
				text-transform: uppercase;
			}

			h4 {
				margin-bottom: 19px;
				color: $color-blue;

				a {
					transition: color .4s;

					&:hover,
					&:focus {
						color: $color-orange;
						text-decoration: none;
					}
				}
			}

			p {
				margin-bottom: 27px;
				font-size: 14px;

			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.news-item {

		.news {

			&__content {
				padding: 0;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.news-items {

		&:last-child {
			margin-bottom: -60px;
		}

		.news-item {
			margin-bottom: 60px;
		}
	}

	.news-item {
		margin-bottom: 60px;

		.news {

			&__image {
				margin-bottom: 25px;
			}

			&__content {

				h4 {
					font-size: 20px;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.news-items {

		&:last-child {
			margin-bottom: -40px;
		}

		.news-item {

			margin-bottom: 40px;
		}
	}
}
