/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */

.popup-white {

	&.mfp-bg {
		background: $color-light;
		opacity: 1;
	}

	.mfp-content {
		vertical-align: top;
	}
}

.popup {

	.mfp-close {
		position: absolute;
		top: 50px;
		right: 187px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 1px solid #ddd;
		background: $color-light;
		font-size: 0;
		opacity: 1;
		transition: transform .4s, background .4s;

		&:hover {
			background: #ddd;
			transform: rotate(90deg);

			&:before,
			&:after {
				background: $color-light;
			}
		}

		&:before,
		&:after {
			position: absolute;
			top: 18px;
			left: 28px;
			width: 2px;
			height: 21px;
			background: $color-grey;
			content: '';
			transition: background .4s;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.popup {

		.mfp-close {
			right: 50px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.popup {

		.mfp-close {
			top: 15px;
			right: 15px;
			width: 50px;
			height: 50px;

			&:before,
			&:after {
				top: 14px;
				left: 23px;
				height: 18px;
			}
		}
	}
}

.video-container {
  position: relative;
  padding-bottom: 51.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}