/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {

	ul {
		font-size: 0;
		list-style: none outside none;
	}

	li {
		display: inline-block;
		margin: 0 17px;
		vertical-align: top;
	}

	a {
		display: block;
		font-size: 20px;
		transition: color .4s;

		&:focus,
		&:hover {
			color: $color-orange;
		}
	}
}

/* ------------------------------------------------------------ *\
	Socials Alt
\* ------------------------------------------------------------ */

.socials-alt {
	text-align: center;

	ul {
		list-style: none outside none;
	}

	li {
		margin-bottom: 13px;
	}

	a {
		display: block;
		font-size: 23px;
		line-height: 1.3;
		color: $color-blue;
		transition: color .4s;

		&:focus,
		&:hover {
			color: $color-orange;
			text-decoration: none;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.socials-alt {
		text-align: center;

		ul {
			font-size: 0;
		}

		li {
			display: inline-block;
			margin: 0 10px;
			vertical-align: middle;
		}
	}
}
