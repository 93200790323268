/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	padding: 121px 0 93px;
	font-size: 18px;
	line-height: 1.56;

	h6 {
		margin-bottom: 29px;
		color: $color-blue;
		font-weight: 600;
	}

	.article {

		&__inner {
			margin: 0 -30px;
		}

		&__head {
			margin-bottom: 43px;
			text-align: center;
		}

		&__title {
			position: relative;
			max-width: 970px;
			padding-top: 21px;
			margin-left: auto;
			margin-right: auto;
			color: $color-blue;

			&:before {
				position: absolute;
				top: 0;
				left: 50%;
				width: 40px;
				height: 2px;
				margin-left: -20px;
				background: $color-orange;
				content: '';
			}
		}

		&__meta {
			display: block;
			padding: 5px 0;
			margin-bottom: 21px;
			font-size: 0;

			small {
				display: inline-block;
				margin: 0 10px;
				font-size: 12px;
				color: $color-grey;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				vertical-align: top;
			}
		}

		&__body {
			position: relative;

			.socials-alt {
				position: absolute;
				top: 0;
				left: -170px;
			}
		}

		&__image {
			margin: 40px -70px 46px;

			img {
				width: 100%;
			}
		}

		&__actions {
			display: flex;
			justify-content: space-between;
			padding-top: 41px;
			border-top: 1px solid #dddddd;
			margin-top: 98px;

			.link--next {
				margin-left: 9px;
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.article {

		[class^="col-"] {
			position: static;
		}

		.article {

			&__inner {
				margin: 0;
			}

			&__body {
				position: static;

				.socials-alt {
					top: auto;
					left: 15px;
				}
			}

			&__image {
				margin-left: -50px;
				margin-right: -50px;
			}

			&__actions {
				margin-top: 50px;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.article {
		padding: 100px 0;

		.article {

			&__inner {
				padding: 0 30px;
			}

			&__image {
				position: relative;
				left: 50%;
				right: 50%;
				width: 100vw;
				margin-left: -50vw;
				margin-right: -50vw;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.article {
		padding: 70px 0;

		h6 {
			margin-bottom: 20px;
		}

		.article {

			&__inner {
				padding: 0;
			}

			&__body {

				.socials-alt {
					position: static;
					margin-bottom: 30px;
				}
			}

			&__actions {
				flex-wrap: wrap;
				padding-top: 40px;
				margin-top: 40px;

				.link {

					&--alt {
						flex: 0 1 100%;
						order: 1;
						margin-top: 15px;
						text-align: center;
					}
				}
			}
		}
	}
}
