/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	display: flex;
	justify-content: space-between;
	padding: 0 40px 0 10px;

	&:before,
	&:after {
		display: none;
	}

	ul {
		list-style: none outside none;

		ul {
			position: absolute;
			top: 100%;
			left: 50%;
			width: 167px;
			padding: 9px 0;
			background: rgba($color-blue, .9);
			color: $color-light;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-50%);
			transition: opacity .4s, visibility .4s;

			a {
				padding: 4px 15px 4px 22px;
				transition: opacity .4s;

				&:hover,
				&:focus {
					color: inherit;
					text-decoration: none;
					opacity: .7;
				}
			}
		}
	}

	li {

		&:hover {

			> ul {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	a {
		display: block;
		font-size: 13px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}

	> ul {
		margin-bottom: 0;

		> li {
			position: relative;
			display: inline-block;
			margin: 0 15px;
			vertical-align: top;

			> a {
				position: relative;
				padding: 7px 0 26px;
				color: $color-darkgrey;
				transition: color .4s;

				&:before {
					position: absolute;
					top: 0;
					left: 50%;
					width: 20px;
					height: 2px;
					margin-left: -10px;
					background: $color-orange;
					opacity: 0;
					content: '';
					transition: opacity .4s;
				}

				&:hover,
				&:focus {
					color: $color-blue;
					text-decoration: none;

					&:before {
						opacity: 1;
					}
				}
			}

			&.active,
			&:hover {

				> a {

					color: $color-blue;
					text-decoration: none;

					&:before {
						opacity: 1;
					}
				}
			}

			&.alt {

				ul {
					width: 191px;
				}
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.nav {
		padding: 0;

		> ul {
			margin: 0 -8px;

			> li {
				margin: 0 8px;
			}
		}
	}
}

@media (max-width: 1024px) {
	.nav {

		ul {

			ul {
				display: none;
				opacity: 1;
				visibility: visible;
			}
		}

		li {

			&:hover {

				> ul {
					display: block;
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.nav {

		> ul {
			display: flex;
			flex: 1 0 auto;
			margin: 0;

			> li {
				flex: 1 0 auto;
				margin: 0;

				> a {
					padding-bottom: 10px;
					text-align: center;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.nav {
		position: fixed;
		top: 87px;
		left: 100%;
		overflow-y: auto;
		display: block;
		width: 100%;
		height: calc(100% - 87px);
		padding: 37px 12px;
		background: $color-light;
		transition: left .4s;

		&.expanded {
			left: 0;
		}

		> ul {
			display: block;

			&:first-child {
				border-top: 1px solid #eeeeee;
			}

			> li {
				display: block;
				border-bottom: 1px solid #eeeeee;

				> a {
					position: relative;
					border-top: 1px solid transparent;
					border-bottom: 1px solid transparent;
					padding: 13px 40px 14px 20px;
					color: $color-darkgrey;
					text-align: left;

					&:hover,
					&:focus {
						color: $color-darkgrey;

						&:before {
							opacity: 0;
						}
					}
				}

				&.has-dropdown {

					> a {

						&:after {
							position: absolute;
							top: 17px;
							right: 22px;
							@include chevron(8px, 2px, $color-darkgrey, 0 0 0 0, -45deg, 50% 50%, .4s);
						}
					}
				}

				&.alt {

					> ul {
						width: auto;
					}
				}

				&.expanded {

					> a {
						border-bottom-color: #eeeeee;
						background: $color-lightgrey;

						&:after {
							transform: rotate(-135deg);
						}
					}
				}
			}
		}

		ul {

			ul {
				position: static;
				overflow: hidden;
				max-height: 0;
				display: block;
				width: auto;
				padding: 0;
				background: none;
				color: $color-darkgrey;
				opacity: 1;
				visibility: visible;
				transform: translateX(0);
				transition: max-height .4s;

				li {

					&:first-child {
						margin-top: 17px;
					}

					&:last-child {
						margin-bottom: 17px;
					}
				}

				a {
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}
		}

		li {

			&.expanded {

				> ul {
					max-height: 1000px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Nav Mobile
\* ------------------------------------------------------------ */

/* Mobile */

@include breakpoint-mobile {
	.nav-mobile {
		position: absolute; 
		top: 25px; 
		right: 31px; 
		width: 26px; 
		height: 30px; 
		z-index: 99; 
		margin-top: 0;

		span {
			display: block; 
			width: 100%; 
			height: 2px; 
			border-radius: 3px;
			margin: 6px 0; 
			background: $color-blue;

			&:nth-child(1) {
				transition: all 0.5s ease 0s;
			}
			&:nth-child(2) {
				transition: all 0.4s ease 0s;
			}
			&:nth-child(3) {
				transition: all 0.3s ease 0s;
			}
		}

		&:hover {

			span {
		    	transform: rotate3d(0, 1, 0.1, 180deg);
			}
		}

		&.active {

			span {
				opacity: 0; 
				position: absolute; 
				top: 25%; 
				left: 0; 
				transform-origin: 50% 50%;

				&:first-child {
					opacity: 1;
		    		transform: rotate3d(0, 0, 1, 45deg);
				}

				&:last-child {
					width: 100%; 
					opacity: 1;
		    		transform: rotate3d(0, 0, 1, -45deg);
				}
			}
		}
	}	
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.nav-mobile {
		right: 15px;
	}
}
