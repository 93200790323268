/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
  	margin-top: 25px;
	padding: 25px 0;
	// background: $color-lightgrey;
	font-size: 14px;
	color: #626262;

	h3 {
		color: $color-blue;
	}

	p {
		margin-bottom: 20px;
		line-height: 1.45;

		a {

			&:not([href^="tel:"]) {
				color: $color-orange;
				font-weight: bold;
			}
		}
	}

	.widget {

		&__head {
			margin-bottom: 38px;
		}

		&__title {
			position: relative;
			padding-top: 15px;
			color: $color-blue;
			letter-spacing: 0.05em;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
				height: 2px;
				background: $color-orange;
				content: '';
			}
		}

		&__actions {
			padding-top: 46px;
			margin: 0 -40px;

			.btn-block + .btn-block {
				margin-top: 0;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.widget {
		padding: 0;

		.widget {

			&__actions {
				padding-top: 30px;
				margin-left: -30px;
				margin-right: -30px;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.widget {
		padding: 0;

		.widget {

			&__actions {
				padding-top: 25px;
				margin-left: -15px;
				margin-right: -15px;
			}
		}
	}
}
