/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	position: relative;
	padding: 100px 0;

	.container {
		position: relative;
		z-index: 2;
	  	max-width: 1200px;
	  	width: 100%;
	}

	.section {

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $color-dark;
				opacity: .4;
				content: '';
			}
		}

		&__head {
			margin-bottom: 30px;
			text-align: center;

			h3 {
				display: inline-block;
				min-width: 314px;
				padding: 12px 23px 11px;
				border: 2px solid $color-blue;
				margin-top: 82px;
				font-size: 30px;
				color: $color-blue;
				text-transform: uppercase;
				letter-spacing: 0.05em;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		&__title {
			position: relative;
			max-width: 970px;
			padding-top: 21px;
			margin-left: auto;
			margin-right: auto;
			color: $color-blue;

			&:before {
				position: absolute;
				top: 0;
				left: 50%;
				width: 40px;
				height: 2px;
				margin-left: -20px;
				background: $color-orange;
				content: '';
			}
		}

		&__meta {
			display: block;
			padding: 5px 0;
			margin-bottom: 21px;
			font-size: 0;

			small {
				display: inline-block;
				margin: 0 10px;
				font-size: 12px;
				color: $color-grey;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				vertical-align: top;
			}
		}

		&__foot {
			margin-top: 45px;
		}

		&__actions {
			margin-top: 53px;
			text-align: center;

		  	.btn {
			  margin: 0 10px;
			}
		}

		&__bar {
			padding-top: 50px;
			border-top: 1px solid #dddddd;
			margin: 93px 70px 0;
			text-align: center;
		}
	}

	/*  Section Alt  */
	&--alt {
		color: $color-light;
	}

	/*  Section Lightgrey  */
	&--lightgrey {
		background: $color-lightgrey;
	}

	/*  Section Grey  */
	&--grey {
		background: #e5e5e5;
	}

	/*  Section Orange  */
	&--orange {
		background: $color-orange;
		color: $color-light;

		.section {

			&__title {
				color: inherit;
			}

			&__head {

				h3 {
					border-color: $color-light;
					color: inherit;
				}
			}
		}
	}

	/*  Section Blue  */
	&--blue {
		background: $color-blue;
		color: $color-light;

		.section {

			&__title {
				color: inherit;
			}

			&__head {

				h3 {
					border-color: $color-light;
					color: inherit;
				}
			}
		}

		.news-item {

			.news {

				&__content {

					h4 {
						color: inherit;
					}
				}
			}
		}
	}

	/*  Section Primary  */
	&--primary {

		.section {

			&__head {
				margin-bottom: 64px;
			}

			&__content {
				padding-right: 40px;
			}
		}
	}

	/*  Section Marketing  */
	&--marketing {
		padding: 120px 0 122px;
		text-align: center;
	}

	/*  Section Testimonials  */
	&--testimonials {
		padding: 43px 0;
	}

	/*  Section Group  */
	&--group {
		padding: 159px 0;
		text-align: center;

		h2 {
			display: inline-block;
			padding: 20px 37px 24px;
			margin-bottom: 30px;
			border: 2px solid $color-light;

			small {
				display: block;
				margin-top: 14px;
				font-size: 18px;
				color: inherit;
				letter-spacing: 0.05em;
			}
		}

		.section {

			&__content {

				p {
					margin-bottom: 29px;
				}

				.btn {
					margin: 0 7px;
				}
			}
		}
	}

	/*  Section Valuation  */
	&--valuation {
		padding: 89px 0 112px;
		text-align: center;

		.section {

			&__content {
				padding: 44px 0 0;
				border: 2px solid $color-light;
				margin: 0 -30px;

				p {
					margin-bottom: 32px;
					font-size: 18px;

					a {
						font-weight: bold;

						&:hover,
						&:focus {
							color: inherit;
						}
					}
				}
				
				&-actions {
					position: relative;
					top: 3px;
					display: inline-block;
					padding: 1px 16px 0;
					margin-top: 0;
					margin-bottom: -29px;
					background: $color-orange;
				}
			}

		}
	}

	/*  Section Subscribe  */
	&--subscribe {
		padding: 66px 0 69px;

		.section {

			&__head {
				margin-bottom: 25px;
			}
		}

		.subscribe {
			margin: 0 -30px;
		}
	}

	/*  Section Missiom  */
	&--mission {
		padding-bottom: 95px;
		text-align: center;
	}

	/*  Section Employees  */
	&--employees {
		padding-bottom: 79px;
		text-align: center;

		.section {

			&__foot {
				margin-top: 69px;
			}
		}
	}

	/*  Section Services  */
	&--services {
		padding-bottom: 92px;
	}

	/*  Section Property  */
	&--property {
		padding: 103px 0 100px;
	}

	/*  Section Equity  */
	&--equity {
		padding-bottom: 108px;

		.section {

			&__head {
				margin-bottom: 67px;
			}
		}
	}

	/*  Section Exchange  */
	&--exchange {
		padding-bottom: 94px;

		.section {

			&__head {

				h3 {
					min-width: 328px;
				}
			}
		}
	}

	/*  Section Sales  */
	&--sales {
		padding: 102px 0 75px;

		.section {

			&__head {

				h3 {
					padding-left: 29px;
					padding-right: 29px;
				}
			}
		}
	}

	/*  Section Representation  */
	&--representation {
		padding-bottom: 79px;

		.section {

			&__head {
				margin-bottom: 66px;
			}
		}
	}

	/*  Section Partner  */
	&--partner {
		padding: 103px 0 76px;

		.section {

			&__head {
				margin-bottom: 59px;
			}

			&__foot {
				margin-top: 82px;
			}
		}
	}

	/*  Section Contacts  */
	&--contacts {
		padding-bottom: 98px;
		text-align: center;

		.section {

			&__head {
				margin-bottom: 42px;
			}

			&__content {
				margin: 0 -30px;

				h5 {
					margin-bottom: 13px;
					color: $color-blue
				}

				p {
					font-size: 16px;
					line-height: 1.65;

					a {
						font-weight: 600;

						&:hover,
						&:focus {
							color: inherit;
						}
					}
				}

				.form {
					padding-top: 11px;
				}
			}
		}
	}

	/*  Section Welcome  */
	&--welcome {
		padding: 92px 0 98px;
		text-align: center;

		h4 {
			text-transform: none;
		}
	}

	/*  Section Debt  */
	&--debt {
		padding-bottom: 81px;

		.section {

			&__content {
				padding-right: 35px;
				margin-left: -30px;

				p {
					font-size: 18px;
					line-height: 1.56;
				}

				h6 {
					margin-bottom: 22px;
					color: $color-blue;
					font-weight: 600;
				}
			}
		}
	}

	/*  Section Advantage  */
	&--advantage {
		border-bottom: 1px solid #dddddd;

		.section {

			&__content {
				text-align: center;

				p {
					font-size: 18px;

					& + h4 {
						padding-top: 27px;
					}
				}

				h4 {
					color: $color-blue;
					letter-spacing: 0.05em;
					text-transform: uppercase;
				}
			}

			&__foot {
				margin-top: 39px;

				h6 {
					margin-bottom: 15px;
					font-size: 16px;
					color: $color-blue;
					font-weight: 600;
				}
			}

			&__inner {

				.table {
					margin-bottom: 20px;
				}

				> span {
					display: block;
					margin-bottom: 22px;
					font-size: 12px;
				}

				&-bar {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 30px;

					.select {
						margin-right: 12px;
					}
				}
			}
		}
	}

	/*  Section Partners  */
	&--partners {
		padding-bottom: 91px;

		.section {

			&__head {
				margin-bottom: 72px;
			}
		}
	}

	/*  Section Hightlight  */
	&--highlight {
		padding: 76px 0 51px;
		color: #626262;

		h6 {
			margin-bottom: 4px;
			font-size: 13px;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: 0.06em;
		}

		p,
		.list-dark-dots {
			margin-bottom: 32px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.section {

			&__head {
				margin-bottom: 33px;
				text-align: left;
			}

			&__title {
				padding-top: 15px;

				&:before {
					left: 0;
					margin-left: 0;
				}
			}

			&__aside {
				margin-top: 0;
				margin-bottom: 0;
			}

			&__foot {
				padding-top: 49px;
				border-top: 1px solid #dddddd;
				margin-top: 42px;
				margin-right: -32px;
			}

			&__inner {
				margin-top: 57px;
			}
		}
	}

	/*  Section Offer  */
	&--offer {
		padding: 136px 0;

		.section {

			&__content {
				text-align: center;

				p {
					margin-bottom: 32px;
					font-size: 18px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.form {
					margin: 0 -30px;
				}
			}
		}
	}

	/*  Section Office  */
	&--office {
		padding: 94px 0 55px;

		.section {

			&__head {
				margin-bottom: 68px;
			}
		}
	}

	/*  Section Retail  */
	&--retail {
		padding: 101px 0 85px;

		.section {

			&__head {
				margin-bottom: 68px;
			}
		}
	}

	/*  Section Mixed  */
	&--mixed {
		padding-bottom: 74px;

		.section {

			&__head {
				margin-bottom: 67px;
			}
		}
	}

	/*  Section Insight  */
	&--insight {
		padding-bottom: 93px;
		text-align: center;
		
		.section {

			&__head {
				margin-bottom: 38px;
			}

			&__content {
				margin: 0 -15px;

				p {
					font-size: 18px;
				}
			}
		}
	}

	/*  Section News  */
	&--news {
		padding-bottom: 90px;
		text-align: center;

		.section {

			&__head {
				margin-bottom: 76px;
			}
		}

		.pagination {
			margin-bottom: 9px;
		}
	}

	/*  Section Reports  */
	&--reports {

		.section {

			&__head {
				margin-bottom: 58px;
			}

			&__body {

				&-bar {
					padding: 0 70px;
					margin-bottom: 95px;
				}
			}
		}
	}

	/*  Section Article  */
	&--article {
		padding: 121px 0;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section {

		/*  Section Primary  */
		&--primary {

			.section {

				&__content {
					padding-right: 0;
				}
			}
		}

		/*  Section Subscribe  */
		&--subscribe {

			.subscribe {
				margin: 0;
			}
		}

		/*  Section Debt  */
		&--debt {

			.section {

				&__content {
					padding: 0;
					margin: 0;
				}
			}
		}

		/*  Section Reports  */
		&--reports {

			.section {

				&__body {

					&-bar {
						padding: 0;
					}
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section {

		.section {

			&__bar {
				margin: 60px 0 0;
			}
		}

		/*  Section Valuation  */
		&--valuation {

			.section {

				&__content {
					margin: 0;
				}
			}
		}

		/*  Section Advantage  */
		&--advantage {

			.section {

				&__foot {

					[class^="col-"] {
						margin-bottom: 30px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		/*  Section Hightlight  */
		&--highlight {

			.list-dark-dots,
			p {
				margin-bottom: 20px;
			}

			.section {

				&__aside {
					//margin-top: -100px;
				}

				&__body {

					[class^="col-"] {
						margin-bottom: 30px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				&__foot {
					padding-top: 30px;
					margin-top: 30px;
					margin-right: 0;
				}
			}
		}

		/*  Section Insight  */
		&--insight {

			.section {

				&__content {
					margin: 0;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section {
		padding: 70px 0;

		.section {

			&__head {
				margin-bottom: 30px;

				h3 {
					min-width: 260px;
					padding-left: 15px;
					padding-right: 15px;
					margin-top: 30px;
					font-size: 22px;
				}
			}
		}

		/*  Section Primary  */
		&--primary {

			.section {

				&__body {

					[class^="col-"] {
						margin-bottom: 27px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		/*  Section Marketing  */
		&--marketing {
			padding-bottom: 96px;

			.section {

				&__content {
					padding: 0 1px;
				}

				&__foot {
					margin-top: 41px;
				}
			}
		}

		/*  Section Testimonials  */
		&--testimonials {
			padding-top: 39px;
		  	padding-bottom: 39px;
		}

		/*  Section Group  */
		&--group {
			padding: 93px 0 81px;

			h2 {
				display: block;
				padding: 0;
				border: none;
				margin-bottom: 28px;
				font-size: 35px;
				line-height: 1;

				small {
					margin-top: 10px;
					line-height: 1.6;
				}
			}

			.section {

				&__content {

					.btn {
						margin: 7px;
					}

					&-actions {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;

						.btn {

							&:nth-child(2) {
								order: -1;
							}
						}
					}
				}
			}
		}

		/*  Section Valuation  */
		&--valuation {
			padding: 80px 0 87px;

			.section {

				&__content {
					padding: 0;
					border: none;

					h2 {
						margin-bottom: 29px;
					}
					
					&-actions {
						padding: 0;
						margin: 0;
					}
				}
			}
		}

		/*  Section Subscribe  */
		&--subscribe {
			padding: 68px 0 63px;

			.section {

				&__head {
					margin-bottom: 28px;
				}
			}

			.subscribe {
				padding: 0 10px;
			}
		}

		/*  Section Employees  */
		&--employees {

			.section {

				&__foot {
					margin-top: 45px;
				}
			}
		}

		/*  Section Contacts  */
		&--contacts {

			.section {

				&__content {
					margin: 0;

					.form {
						padding-top: 0;
					}
				}
			}
		}

		/*  Section Debt  */
		&--debt {

			.section {

				&__content {

					p {
						font-size: 17px;
						line-height: 1.59;
					}
				}
			}
		}

		/*  Section Advantage  */
		&--advantage {

			.section {

				&__content {

					p {
						font-size: 17px;
						line-height: 1.59;
					}
				}

				&__inner {

					&-bar {
						display: block;
						margin-bottom: 15px;

						.select {
							margin: 0 0 15px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		/*  Section Partners  */
		&--partners {

			.row {

				[class^="col-"] {
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		/*  Section Highlight  */
		&--highlight {

		  	.section__content {
			  margin-bottom: 25px;
			}

			.section {

				&__aside {
					margin-top: 40px;
					margin-bottom: 30px;
				}

				&__inner {
					margin-top: 72px;
				}
			}
		}

		/*  Section Offer  */
		&--offer {

			.section {

				&__content {

					.form {
						margin: 0;
					}
				}
			}
		}

		/*  Section Reports  */
		&--reports {

			.section {

				&__body {

					&-bar {
						margin-bottom: 50px;
					}
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.section {

		/*  Section Subscribe  */
		&--subscribe {

			.subscribe {
				padding: 0;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section About
\* ------------------------------------------------------------ */

.section-about {
	padding: 93px 0 98px;

	.section {

		&__bar {
			margin-bottom: 46px;

			&-body {
				margin-left: -33px;
			}
		}

		&__image {
			margin: 6px 30px 0 -30px;

			img {
				width: 100%;
			}
		}

		&__content {
			margin-right: -30px;

			h2 {
				margin-bottom: 12px;
				color: $color-blue;
				text-transform: none;
				letter-spacing: 0em;
			}

			> small,
			> span {
				display: block;
			}

			> small {
				margin-bottom: 3px;
				font-size: 14px;
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;

				& + p {
					padding-top: 24px;
				}
			}

			> span {
				margin-bottom: 22px;
				font-size: 15px;
				line-height: 2;
				color: #626262;
				font-weight: 600;

				a {

					&:not([href^="tel:"]) {
						color: $color-orange;
						transition: color .4s;

						&:hover,
						&:focus {
							color: $color-blue;
							text-decoration: none;
						}
					}

					&[href^="tel:"] {

						&:focus,
						&:hover {
							color: inherit;
						}
					}
				}
			}

			p {

				small {
					font-size: 15px;
				}
			}

			hr {
				border-top-color: #dddddd;
				margin: 37px 0;
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.section-about {

		.section {

			&__image {
				margin-right: 0;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-about {
		padding: 70px 0;

		.section {

			&__bar {

				&-body {
					margin-left: 0;
				}
			}

			&__image {
				margin-left: 0;
			}

			&__content {
				margin: 0;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-about {

		.section {

			&__bar {
				margin-bottom: 30px;
			}

			&__image {
				margin-top: 0;
				margin-bottom: 30px;
			}

			&__content {

				hr {
					margin: 30px 0;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Slider
\* ------------------------------------------------------------ */

.section-slider {

	.section {

		&__body {
			padding-right: 1px;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.section-slider {

		.container {
			width: auto;
			padding: 0;
		}

		.section {

			&__body {
				padding-right: 0;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Properties
\* ------------------------------------------------------------ */

.section-properties {
	padding: 50px 0 51px;
	color: $color-light;
	text-align: center;

	.section {

		&__content {
			padding: 67px 15px 74px;

			h2 {
				margin-bottom: 19px;
				line-height: 1.3;
			}

			.btn-default {
				min-width: 213px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Map
\* ------------------------------------------------------------ */

.section-map {
	position: relative;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;	
		width: 100%;
		height: 100%;
		background: $color-light;
		opacity: .8;
		transition: opacity .4s, visibility .4s;
		content: '';
	}

	.section {

		&__overlay {
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 3;	
			width: 100%;
			margin-top: -5px;
			text-align: center;
			transform: translateY(-50%);
			transition: opacity .4s, visibility .4s;

			h1 {
				display: inline-block;	
				padding: 20px 35px;
				border: 2px solid $color-blue;
				color: $color-blue;
				font-weight: 200;
			}
		}
	}

	&.hovered,
	&:hover {

		&:before {
			opacity: 0;
			visibility: hidden;
		}

		.section {

			&__overlay {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.section-map {

		.section {

			&__overlay {
				padding: 0 15px;

				h1 {
					padding: 20px;
					font-size: 30px;
				}
			}
		}
	}
}

.eq-column {
  margin-bottom: 60px;

  @media (max-width: 767px) {
	margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .eq-column {
	height: 565px;
	position: relative;

	.link-below {
	  position: absolute;
	  width: 100%;
	  left: 0;
	  bottom: 40px;

	  &:hover:after {
		display: none;
	  }
	}
  }
}

.three-column-contents {
  column-count: 3;

  @media (max-width: 767px) {
	column-count: 1;
  }
}

.two-column-contents {
  column-count: 2;

  @media (max-width: 767px) {
	column-count: 1;
  }
}

.list-content {
  p {
	margin-bottom: 15px;
  }
}

.whitebg {
  background: #fff;
}

.property-padding {
  background: #f5f5f5;
  padding: 35px 20px;

  @media (max-width: 767px) {
	background: #fff;
	padding: 0;
  }
}