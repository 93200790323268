/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px; 
	background: $color-light; 
	font-family: $font-montserrat; 
	font-size: $font-size-base; 
	line-height: $line-height-base; 
	color: $color-grey; 
	font-weight: 300;
}

a {
	color: inherit; 
	text-decoration: none; 
}
a:hover {
	text-decoration: underline; 
}

a[href^="tel"] {
	text-decoration: none; 
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: calc(#{$line-height-base}em / 2); 
	line-height: 1.1;
	font-weight: 300; 
}

h1 {
	font-size: 45px;
	line-height: 1.11;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

h2 {
	font-size: 35px;
	line-height: 1.14;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

h3 {
	font-size: 25px;
	line-height: 1.2;
}

h4 {
	font-size: 25px;
	line-height: 1.4;
}

h5 {
	font-size: 20px;
	line-height: 1.5;
}

h6 {
	font-size: 18px;
	line-height: 1.45;
}

p,
ul,
ol,
dl,
table,
blockquote {
	margin-bottom: #{$line-height-base}em; 
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0; 
}

/* Mobile */

@include breakpoint-mobile {

	h1 {
		font-size: 35px;
	}

	h2 {
		font-size: 30px;
		line-height: 1.17;
	}
}

.sm-container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.prop-link {
  display: block;
  color: #bd6122;
  margin-top: 10px;
}