/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

/*  Select  */
.select {
	position: relative;

	&:before {
		position: absolute;
		top: 23px;
		right: 30px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 4.5px 0 4.5px;
		border-color: $color-orange transparent transparent transparent;
		content: '';
	}

	select {
		display: block;
		width: 100%;
		min-width: 227px;
		height: 50px;
		padding: 0 40px 0 28px;
		border: 2px solid #cccccc;
		border-radius: 0;
		font-size: 12px;
		line-height: 46px;
		color: $color-grey;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&--small {

		select {
			min-width: 147px;
		}
	}
}

/*  Checkbox and Radio  */
.checkbox,
.radio {
	overflow: hidden;
	position: relative;
	margin: 0;

	input {
		position: absolute;
		left: -9999px;

		&:checked ~ label {

			&:before {
				background: $color-orange;
			}
		}
	}

	label {
		display: block;
		min-height: 30px;
		padding: 2px 0 0 43px;
		font-size: 15px;
		color: #626262;
		font-weight: 300;

		&:before {
			position: absolute;
			top: 1px;
			left: 1px;
			width: 28px;
			height: 28px;
			border: 7px solid $color-light;
			border-radius: 50%;
			background: $color-light;
			box-shadow: 0 0 0 1px #cecece;
			content: '';
			transition: background .4s;
		}
	}
}
