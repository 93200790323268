/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
	position: relative;
	
	.search {

		&__field {
			display: block;
			width: 100%;
			height: 50px;
			padding: 0 50px 0 18px;
			border: 1px solid #cccccc;
			border-radius: 0;
			font-size: 12px;
			line-height: 48px;
			font-weight: 600;
			letter-spacing: 0.05em;
			color: #626262;
			appearance: none;
		}

		&__btn {
			position: absolute;
			top: 5px;
			right: 6px;
			width: 40px;
			height: 40px;
			border: 2px solid $color-orange;
			border-radius: 0;
			background: $color-orange;
			font-size: 12px;
			color: $color-light;
			font-weight: normal;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			transition: background .4s, color .4s;
			appearance: none;

			&:hover,
			&:focus {
				background: $color-light;
				color: $color-orange;
			}
		}
	}
}
