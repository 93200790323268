/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	padding-top: 105px;
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.wrapper {
		padding-top: 118px;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.wrapper {
		padding-top: 87px;
	}
}


.fourzerofour {
  padding: 250px 0;
  text-align: center;
}

.fourzerofour h1 {
  border: 2px solid #093e52;
  max-width: 350px;
  margin: 0 auto 50px;
  padding: 15px;
  color: #093e52;
}

.fourzerofour a {
  color: #bd6122;
  font-weight: 600;
}

@media (max-width: 767px) {
  .fourzerofour {
	padding: 100px 0;
  }
}