/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */

.features {

	&--secondary {

		.feature {

			.feature {

				&__content {

					&-body {
						padding-top: 45px;
						padding-bottom: 20px;
						vertical-align: middle;
					}
				}
			}

			&:first-child {

				.feature {

					&__content {

						&-body {
							padding-top: 20px;
							padding-bottom: 30px;
						}
					}
				}
			}
		}
	}

	&--reversed {

		.feature {

			.feature {

				&__content {

					p {
						font-size: 18px;
					}

					&-body {
						padding-bottom: 37px;
					}
				}
			}

			&:first-child {

				.feature {

					&__content {

						&-body {
							padding-top: 31px;
						}
					}
				}
			}

			&:nth-child(odd) {
				background: $color-blue;

				.feature {

					&__content {

						h2 {

							&:before {
								background: $color-orange;
							}
						}
					}
				}
			}

			&:nth-child(even) {
				background: $color-orange;
			}
		}
	}
}

.feature {
	position: relative;
	background: $color-orange;
	color: $color-light;

	.container {
		position: static;
	}

	.feature {

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;

			&-alt {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #eeeeee;
			}

			&-inner {
				padding: 72px 77px;

				h3 {
					margin-bottom: 30px;
					font-size: 30px;
					letter-spacing: 0.05em;
					text-transform: uppercase;
				}

				.list-progress-items {
					padding-left: 3px;
				}
			}

			&-body {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				padding: 30px;
				background: #eeeeee;
			}
		}

		&__content {
			display: table;
			height: 700px;
			margin-left: 50%;
			width: 50%;

			h2 {
				position: relative;
				padding-top: 21px;
				margin-bottom: 30px;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					width: 40px;
					height: 2px;
					background: $color-light;
					content: '';
				}
			}

			.btn-default {
				min-width: 188px;
				margin-top: 9px;
			}

			&-image {
				position: relative;
				z-index: 2;
				margin-bottom: -2px;
			}

			&-body {
				display: table-cell;
				padding: 97px 84px 20px 84px;
				vertical-align: top;
			}

			&-inner {
				max-width: 500px;
			}
		}
	}

	&:nth-child(even) {
		background: $color-blue;

		.feature {

			&__image {
				left: auto;
				right: 0;
			}

			&__content {
				margin-left: 0;
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.feature {

		.feature {

			&__image {

				&-inner {
					padding: 40px 30px 30px;
				}
			}

			&__content {

				&-body {
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.features {

		&--secondary {
			
			.feature {

				&:first-child {

					.feature {

						&__content {

							&-body {
								padding: 30px;
							}
						}
					}
				}
			}
		}
	}

	.feature {

		.feature {

			&__content {
				display: block;
				width: auto;
				height: auto;
				margin-left: 0;

				&-body {
					display: block;
					padding: 30px;
				}

				&-inner {
					max-width: none;
				}
			}

			&__image {
				position: relative;
				top: 0;
				left: 0;
				width: auto;
				height: auto;
				padding-top: 50%;

				&-inner {
					margin-top: -50%;
				}

				&-body {
					position: static;
					margin-top: -50%;
				}

				&-alt {
					padding: 0;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {

	.features {

		&--secondary {

			.feature {

				.feature {

					&__content {

						&-body {
							padding: 97px 32px 101px;
						}
					}
				}

				&:first-child {

					.feature {

						&__content {

							&-body {
								padding: 97px 32px 101px;
							}
						}
					}
				}
			}
		}

		&--reversed {

			.feature {

				.feature {

					&__content {

						p {
							font-size: 17px;
						}
					}
				}
			}
		}
	}

	.feature {

		.feature {

			&__content {

				&-body {
					padding: 97px 32px 101px;

					.link {
						display: inline-block;
					}
				}
			}

			&__image {
				padding-top: 83%;

				&-inner {
					padding: 90px 30px;
					margin-top: -83%;

					h3 {
						font-size: 25px;

						br {
							display: none;
						}
					}
				}

				&-body {
					padding: 60px 30px;
					margin-top: -83%;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.features {

		&--secondary {

			.feature {

				&:first-child {

					.feature {

						&__content {

							&-body {
								padding: 70px 15px;
							}
						}
					}
				}

				.feature {

					&__content {

						&-body {
							padding: 70px 15px;
						}
					}
				}
			}
		}
	}

	.feature {

		.feature {

			&__content {

				&-body {
					padding: 70px 15px;
				}
			}

			&__image {

				&-inner {
					padding: 70px 15px;
				}

				&-body {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Featured Items
\* ------------------------------------------------------------ */

.featured-items {

	&:last-child {
		margin-bottom: -34px;
	}

	.row {
		margin: 0 -10px;

		[class^="col-"] {
			padding: 0 10px;

			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
}

.featured-item {
	margin-bottom: 34px;
	text-align: center;

	&:hover {

		.featured {

			&__body {

				&:before {
					top: 0;
					height: 100%;
				}
			}

			&__content {
				opacity: 1;
				transform: translateY(-50%);
				transition-delay: .4s;
			}
		}
	}

	h4 {
		text-transform: none;
	}

	.featured {

		&__body {
			position: relative;
			overflow: hidden;

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 0;
				background: #222222;
				opacity: .6;
				content: '';
				transition: top .4s, height .4s;
			}
		}

		&__image {

			img {
				width: 100%;
			}
		}

		&__content {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			padding: 0 15px;
			margin-top: -4px;
			color: $color-light;
			opacity: 0;
			transition: opacity .4s, transform .4s;
			transform: translateY(-25%);

			h4 {
				margin-bottom: 1px;
			}

			> span {
				display: block;
				margin-bottom: 29px;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0.05em;
				text-transform: uppercase;
			}

			p {
				margin-bottom: 35px;
				font-size: 14px;
				line-height: 1.71;
			}
		}

		&__foot {
			padding: 23px 0;

			h4 {
				margin-bottom: 11px;
				color: $color-blue;
			}

			> span {
				display: block;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0.05em;
				text-transform: uppercase;
			}
		}
	}
}

/* Large Desktop */

@include breakpoint-large-desktop {
	.featured-item {

		.featured {

			&__content {

				> span {
					margin-bottom: 10px;
				}

				p {
					margin-bottom: 15px;
				}
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.featured-items {

		.row {

			[class^="col-"] {

				&:nth-child(3n+1) {
					clear: none;
				}

				&:nth-child(odd) {
					clear: both;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.featured-items {

		&:last-child {
			margin-bottom: -25px;
		}
	}

	.featured-item {
		margin-bottom: 25px;

		.featured {

			&__content {

				h4 {
					font-size: 22px;
				}

				p {
					line-height: 1.4;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.featured-item {

		.featured {

			&__content {

				> span {
					margin-bottom: 6px;
				}

				p {
					margin-bottom: 10px;
				}
			}
		}
	}
}
