/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	text-align: left;

	.form {

		&__notice {
			display: block;
			margin-bottom: 23px;
			font-size: 12px;
		}
	}

	form {
		position: relative;
	}

	.gform_heading {
		display: none;
	}

	.gform_fields {
		margin: 0 -15px;
		list-style: none outside none;
	}

	.gfield {
		max-width: none;
		padding: 0 15px;
		margin-bottom: 25px;
	}

	.gfield_label {
		display: block;
		margin-bottom: 7px;
		font-size: 12px;
		color: #626262;
		font-weight: 600;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.ginput_container {

		textarea,
		select,
		> input {
			display: block;
			width: 100%;
			height: 50px;
			padding: 0 16px;
			border-radius: 0;
			border: 1px solid #cccccc;
			background: none;
			font-size: 16px;
			color: #626262;
			line-height: 50px;
			font-weight: 300;
			appearance: none;

			&::placeholder {
				color: #626262;
			}
		}

		select {
			cursor: pointer;

			&::-ms-expand {
				display: none;
			}
		}

		textarea {
			height: 250px;
			padding-top: 14px;
			padding-bottom: 15px;
			line-height: 1.3;
			resize: none;
		}
	}



	.gfield-checkbox {

		.gfield_label {
			display: none;
		}
	}

	.ginput_container_select {
		position: relative;

		&:before {
			position: absolute;
			top: 21px;
			right: 13px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 6.5px 0 6.5px;
			border-color: #aaaaaa transparent transparent transparent;
			content: '';
		}

		select {
			padding-right: 28px;
		}
	}

	.gfield-radio {
		max-width: 100%;
		margin-bottom: 29px;

		.gfield_label {
			margin-bottom: 17px;
		}
	}

	.gfield_radio,
	.gfield_checkbox {
		list-style: none outside none;

		li {
			position: relative;
			overflow: hidden;

			input {
				position: absolute;
				left: -9999px;

				&:checked ~ label {

					&:before {
						background: $color-orange;
					}
				}
			}

			label {
				position: relative;
				display: block;
				min-height: 30px;
				padding: 1px 9px 0 43px;
				margin-bottom: 0;
				font-size: 15px;
				line-height: 1.7;
				color: #626262;
				font-weight: 300;

				&:before {
					position: absolute;
					top: 1px;
					left: 1px;
					width: 28px;
					height: 28px;
					border: 7px solid $color-light;
					border-radius: 50%;
					background: $color-light;
					box-shadow: 0 0 0 1px #cecece;
					content: '';
					transition: background .4s;
				}
			}
		}
	}

	.gfield_checkbox {

		li {

			label {
				min-height: 41px;
			}
		}
	}

	.gfield_radio {
		display: flex;
		flex-wrap: wrap;
		padding-right: 30px;

		li {
			flex: 0 0 33.33%;
			max-width: 33.33%;
		}
	}

	.gfield-radio {

		&.alt {
			margin-bottom: 24px;

			.gfield_label {
				margin-bottom: 13px;
				font-size: 15px;
				font-weight: 300;
				text-transform: none;
				letter-spacing: 0;
			}

			.gfield_radio {

				li {
					max-width: none;
					margin-right: 23px;
				}
			}
		}

	}

	.gform_button {
		display: inline-block;
		min-width: 160px;
		height: 50px;
		padding: 0 28px;
		border: 2px solid $color-orange;
		border-radius: 0;
		background: $color-orange;
		font-size: 12px;
		line-height: 48px;
		color: $color-light;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.07em;
		text-align: center;
		appearance: none;
		transition: border .4s, background .4s, color .4s;

		&:hover,
		&:focus {
			border-color: $color-orange;
			background: $color-light;
			color: $color-orange;
		}
	}

	.gfield-html {
		max-width: 100%;

		.gfield-inner {
			padding: 29px 14px 20px 39px;
			border: 1px solid #cccccc;
			font-size: 12px;
			line-height: 1.5;
			color: #626262;

			h6 {
				margin-bottom: 0;
				font-size: 12px;
				line-height: 1.5;
				font-weight: 600;
			}

			p {
				font-size: 12px;
				line-height: 1.5;
			}

			&-content {
				overflow-y: auto;
				max-height: 199px;
			}

			.mCSB_scrollTools {
				width: 2px;
			}

			.mCSB_inside>.mCSB_container {
				margin-right: 20px;
			}

			.mCSB_scrollTools .mCSB_draggerRail {
				background: #dddddd;
			}

			.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
				width: 2px;
				background: $color-orange;
			}
		}
	}

	/*  Form Alt  */
	&--alt {

		.gfield-large {
			margin-bottom: 16px;
		}

		.gfield-small {
			max-width: 34.8%;
			margin-bottom: 22px;

			.gfield_label {
				display: none;
			}

			&.gfield-select {
				max-width: 30.4%;
			}
		}

		.gfield-checkbox {
			
			&:last-child {
				margin-bottom: 0;

				.ginput_container {
					margin-right: -10px;
				}
			}
		}

		.gfield_checkbox {
			padding-top: 23px;
		}

		.gform_footer {
			position: absolute;
			bottom: 1px;
			right: 0;
			width: 49%;
			text-align: right;
		}
	}

	/*  Form Contacts  */
	&--contacts {

		.gform_button {
			display: block;
			width: 100%;
		}
	}

	/*  Form Offer  */
	&--offer {

		.form {

			&__notice {
				margin-bottom: 20px;
			}
		}

		.gfield-checkbox {

			&:last-child {
				position: relative;
				padding-top: 14px;
				margin-top: 16px;

				&:before {
					position: absolute;
					top: 0;
					left: 15px;
					right: 0;
					width: calc(200% - 30px);
					border-top: 1px solid #dddddd;
					content: '';
				}
			}
		}
	}

	/*  Form Download  */
	&--download {

		.gfield_checkbox {
			padding-top: 5px;
		}

		.gfield_checkbox {

			li {

				label {
					min-height: 51px;
				}
			}
		}
	}

  .validation_error {
	font-size: 14px;
	text-align: center;
	margin-bottom: 35px;
  }

  .validation_message {
	color: red;
	font-size: 14px;
	margin-top: 3px;
  }
}

/* Mobile */

@include breakpoint-mobile {
	.form {

		.gform_fields {
			display: block;
		}

		.gfield {
			max-width: none;
		}

		.gform_footer {
			position: static;
			padding-top: 25px;
			width: auto;
		}

		.gform_button {
			display: block;
			width: 100%;
		}

		.gfield_radio {
			display: block;
			padding-right: 0;

			li {
				max-width: none;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.gfield-html {

			.gfield-inner {
				padding: 20px 15px 20px 20px;
			}
		}

		&--alt {

			.gfield-small,
			.gfield-large {
				margin-bottom: 25px;
			}

			.gfield-small {

				&.gfield-select {
					max-width: none;
				}
			}

			.gfield_checkbox {
				padding-top: 0;
			}
		}

		&--offer {

			.gfield-checkbox {

				&:last-child {

					&:before {
						left: 15px;
						right: 15px;
						width: auto;
					}
				}
			}
		}
	}
}

.gform_confirmation_wrapper  {
  text-align: center;
}

@media (min-width: 641px) {
  .gform_wrapper .top_label li.gfield.gf_left_third, .gform_wrapper .top_label li.gfield.gf_middle_third, .gform_wrapper .top_label li.gfield.gf_right_third {
	width: 33.3%;
	float: left;
  }
  .gform_wrapper .top_label li.gfield.gf_left_half, .gform_wrapper .top_label li.gfield.gf_right_half {
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding-right: 16px;
	float: none;
  }
}