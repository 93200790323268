/* ------------------------------------------------------------ *\
	Employees
\* ------------------------------------------------------------ */

.employees {

	&:last-child {
		margin-bottom: -53px;
	}

	.row {
		overflow: hidden;

		&:before,
		&:after {
			display: none;
		}

		[class^="col-"] {
		  	width: 24.5%;
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
		  	float: none;
		  	vertical-align: top;

		  	@media (max-width: 767px) {
			  width: 100%;
			}

			&:nth-child(4n+1) {
				clear: both;
			}
		}
	}
}

.employee {
	margin-bottom: 53px;

	a {

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
		}

		&:hover {

			.employee {

				&__image {

					img {
						filter: grayscale(0);
					}
				}
			}
		}
	}

	.employee {

		&__image {
			overflow: hidden;

			img {
				width: 100%;
				filter: grayscale(100%);
				transition: filter .4s;
			}
		}

		&__content {
			padding: 20px 0;

			h3 {
				margin-bottom: 4px;
				color: $color-blue;
			}

			> span {
				display: block;
				font-size: 15px;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.employees {

		.row {

			[class^="col-"] {

				&:nth-child(4n+1) {
					clear: none;
				}

				&:nth-child(3n+1) {
					clear: both;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.employees {

		&:last-child {
			margin-bottom: -40px;
		}
	}
	
	.employee {
		margin-bottom: 40px;
	}
}

.section--employees + .section--employees {
  padding-top: 0;
}