/* ------------------------------------------------------------ *\
	Video Item
\* ------------------------------------------------------------ */

.video-item {
	position: relative;

	&:hover {

		.btn-play {
			box-shadow: 0 0 0 19px rgba($color-light, 0.8);
		}
	}

	&.expanded {

		.video {

			&__body {
				opacity: 0;
			}

			&__iframe {
				z-index: 3;
				opacity: 1;
			}
		}
	}

	.video {

		&__body {
			position: relative;
			z-index: 2;
		}

		&__image {

			img {
				width: 100%;
			}
		}

		&__actions {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__iframe {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background: $color-dark;
			opacity: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.btn-close {
				position: absolute;
				top: -70px;
				right: -160px;
				z-index: 2;
			}
		}
	}
}

@media (max-width: 1549px) {
	.video-item {

		.video {

			&__iframe {

				.btn-close {
					right: 0;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.video-item {

		&:hover {

			.btn-play {
				box-shadow: 0 0 0 14px rgba($color-light, 0.8);
			}
		}		

		.video {

			&__iframe {

				.btn-close {
					top: -58px;
				}
			}
		}
	}
}
