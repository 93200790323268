/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	List Progress Items
\* ------------------------------------------------------------ */

.list-progress-items {

	li {
		margin-bottom: 17px;

		> span {
			display: block;
			padding-left: 2px;
			margin-bottom: 3px;
			font-size: 11px;
			font-weight: normal;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}

		.progress {
			margin-bottom: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Orange Squares
\* ------------------------------------------------------------ */

.list-orange-squares {

	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 1.56;
	  	list-style: none;

		&:before {
			position: absolute;
			top: 12px;
			left: 1px;
			width: 5px;
			height: 5px;
			background: $color-orange;
			content: '';
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-orange-squares {

		li {
			padding-left: 25px;
			font-size: 17px;
			line-height: 1.59;
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.list-orange-squares {

		li {
			padding-left: 20px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */

.list-partners {

	li {
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 1.56;
	  	list-style: none;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.list-partners {

		li {
			font-size: 17px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Dark Dots
\* ------------------------------------------------------------ */

.list-dark-dots {

	li {
		position: relative;
		padding-left: 20px;

		&:before {
			position: absolute;
			top: 13px;
			left: 11px;
			width: 2px;
			height: 2px;
			border-radius: 50%;
			background: #626262;	
			content: '';
		}

		small {
			font-size: 13px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Squares
\* ------------------------------------------------------------ */

.list-squares {
	margin: 0 -29px;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 29px;
		vertical-align: top;
	}

	a {
		position: relative;
		display: block;
		padding-left: 24px;
		font-size: 13px;
		font-weight: normal;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		&:before {
			position: absolute;
			top: 2px;
			left: 0;
			width: 15px;
			height: 15px;
			border: 2px solid #dddddd;
			font-size: 11px;
			line-height: 11px;
			text-align: center;
			content: '';
		}

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
		}
	}
  	a.current {
	  outline: 0;

	  &:before {
		content: '\2713';
	  }
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-squares {
		margin: 0 -10px;

		li {
			margin: 0 10px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.list-squares {
		margin: 0 -3px;

		li {
			margin: 0 3px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Checkboxes
\* ------------------------------------------------------------ */

.list-checkboxes {

	li {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
