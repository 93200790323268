/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	text-align: center;

	.owl-item {
		backface-visibility: visible;
	}

	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		background: rgba($color-light, .9);
		font-size: 0;
		transition: background .4s, opacity .4s, transform .4s;

		&:before {
			position: absolute;
			top: 19px;
			left: 21px;
			@include chevron(12px, 2px, $color-orange, 0 0 0 0, 45deg, 50% 50%, .4s);
		}

		&:hover {
			background: rgba($color-orange, .9);

			&:before {
				border-color: $color-light;
			}
		}
	}

	.owl-prev {
		left: 50px;
	}

	.owl-next {
		right: 50px;

		&:before {
			left: 15px;
			transform: rotate(-135deg);
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 31px;
		left: 0;
		width: 100%;
		font-size: 0;
		text-align: center;
	}

	.owl-dot {
		display: inline-block;
		margin: 5px;
		vertical-align: top;

		&:hover {

			span {
				background: $color-orange;
			}
		}

		span {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: $color-light;
			transition: background .4s;
		}

		&.active {

			span {
				background: $color-orange;
			}
		}
	}

	&--default {
		overflow: hidden;
		color: $color-light;

		.slider {

			&__slide {

				&-body {
					position: relative;
				}

				&-image {
					position: relative;
					min-height: 750px;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: $color-dark;
						opacity: .2;
						content: '';
					}
				}

				&-content {
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					margin-top: 10px;
					transform: translateY(-50%);

					h1 {
						display: inline-block;
						padding: 28px 44px 30px;
						border: 2px solid $color-light;
						margin-bottom: 24px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					h6 {
						font-style: italic;
					}
				}

				&-foot {
					padding: 0 30px;
					color: $color-grey;

					h6 {
						padding: 44px 0;
						font-size: 18px;
						line-height: 1.45;
						font-style: italic;
						font-weight: normal;
					}
				}
			}
		}

		&:hover {

			.owl-prev,
			.owl-next {
				opacity: 1;
				transform: translateY(0);
			}
		}

		.owl-prev,
		.owl-next {
			transform: translateY(30px);
			opacity: 0;
		}

		&-alt {

			.slider {

				&__slide {

					&-content {
						margin-top: 0;

						h1 {
							padding-left: 41px;
							padding-right: 41px;
							font-weight: 200;
						}
					}
				}
			}
		}
	}

	&--testimonials {
		padding-top: 50px;
		padding-bottom: 120px;

		.owl-dots {
			bottom: -118px;
		}

		.owl-dot {

			span {
				background: #cccccc;
			}
		}

		.owl-next,
		.owl-prev {
			margin-top: 9px;	
		}

		.testimonial {

			&--large {
				margin: 0 -12px;
			}
		}

		&-large {
			padding-bottom: 102px;
			
			.owl-dots {
				bottom: -100px;
			}

			.owl-next,
			.owl-prev {
				margin-top: 1px;
			}
		}
	}

	&--gallery {

		.owl-carousel .owl-stage-outer {
			overflow: visible;
		}

		.slider {

			&__slide {
				position: relative; 			

				&-image {
					position: relative; 
					padding-top: 61.4%;
					transform: scale(.886);
					transform-origin: center right;
					transition: transform 1s, transform-origin 1s;

					&:before {
						position: absolute; 
						top: 0; 
						left: 0; 
						z-index: 2; 
						width: 100%; 
						height: 100%; 
						background: $color-light; 
						opacity: .8; 
						content: ''; 
						transition: background .4s, opacity .4s;
					}

					img {
						display: inline-block; 
						width: auto; 
					}
				}
			}
		}

		.owl-item.active {

			.slider__slide-image {
				transform: scale(1);

				&:before {
					opacity: 0; 
				}
			}
		}

		.owl-item.active ~ .owl-item {

			.slider__slide-image {
				transform-origin: center left;
			}
		}

		.owl-dots {
			display: none;
		}

		.owl-prev,
		.owl-next {
			margin-top: -28px;
		}

		.owl-prev {
			left: 0;
		}

		.owl-next {
			right: 0;
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.slider {

		.owl-prev {
			left: 30px;
		}

		.owl-next {
			right: 30px;
		}

		&--gallery {

			.owl-prev {
				left: 0;
			}

			.owl-next {
				right: 0;
			}
		}
	}
}

@media (max-width: 1024px) {
	.slider {

		&--default {

			.slider {

				&__slide {

					&-image {
						transform: translateY(0) !important;
					}
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.slider {

		&--default {

			.slider {

				&__slide {

					&-image {
						min-height: 500px;
					}
				}
			}
		}

		&--testimonials {
			padding-bottom: 80px;

			.slider {

				&__slide {

					&-content {
						padding: 0 60px;
					}
				}
			}

			.testimonial {

				&--large {
					margin: 0;
				}
			}

			.owl-dots {
				bottom: -80px;
			}
		}

		&--gallery {

			.slider {

				&__slide {

					&-image {
						transform: scale(1);

						&:before {
							opacity: 0;
						}
					}
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.slider {

		&--default {

			.slider {

				&__slide {

					&-image {
						min-height: 400px;
					}

					&-content {
						margin-top: 0;

						.container {
							padding: 0 26px;
						}

						h1 {
							padding: 10px 24px;
							margin-left: auto;
							margin-right: auto;
							margin-bottom: 0;
							font-size: 20px;
							line-height: 1.3;
						}
					}

					h6 {

						br {
							display: none;
						}
					}
				}
			}

			.owl-nav {
				display: none;
			}

			.owl-dots {
				top: 361px;
				bottom: auto;
			}

			&-alt {
				margin-bottom: 0;
			}
		}

		&--testimonials {
			padding-top: 0px;
			padding-bottom: 60px;

			.owl-nav {
				display: none;
			}

			.owl-dots {
				bottom: -60px;
			}

			.slider {

				&__slide {

					&-content {
						padding: 0;
					}
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.slider {

		&--default {

			.slider {

				&__slide {

					&-content {

						.container {
							padding: 0 15px;
						}

						h1 {
							padding-left: 15px;
							padding-right: 15px;
						}
					}
				}
			}
		}
	}
}

.home {
  .slider--default .slider__slide-image {
	&:before {
	  display: none;
	}
  }
  .homeslide.slider--default .slider__slide-image.show-bg {
	&:before {
	  display: block;
	}
  }
}
