/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative;
	overflow: hidden;
	color: $color-light;

	.intro {

		&__image {
			position: relative;
			min-height: 750px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $color-dark;
				opacity: .4;
				content: '';
			}
		}

		&__content {
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 2;
			width: 100%;
			margin-top: 0;
			text-align: center;
			transform: translateY(-50%);

			h2,
			h1 {
				display: inline-block;
				padding: 28px 44px 30px;
				border: 2px solid $color-light;
				margin-bottom: 24px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			h2 {
				padding-top: 24px;
				padding-bottom: 24px;
				font-size: 40px;
				font-weight: 200;
			}
		}
	}
}

@media (max-width: 1024px) {
	.intro {

		.intro {

			&__image {
				transform: translateY(0) !important;
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.intro {

		.intro {

			&__image {
				min-height: 500px;
			}

			&__content {

				h1,
				h2 {
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.intro {

		.intro {

			&__image {
				min-height: 400px;
			}

			&__content {

				.container {
					padding: 0 26px;
				}

				h2,
				h1 {
					padding: 10px 24px;
					margin-left: auto;
					margin-right: auto;
					font-size: 20px;
					line-height: 1.3;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.intro {

		.intro {

			&__content {

				.container {
					padding: 0 15px;
				}

				h1,
				h2 {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}
