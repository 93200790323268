/* ------------------------------------------------------------ *\
	Progress
\* ------------------------------------------------------------ */

.progress {
	overflow: visible;
	height: 40px;
	border-radius: 0;
	background: none;
	box-shadow: none;

	.progress-bar {
		position: relative;
		background: rgba($color-light, .5);

		span {
			position: absolute;
			top: 6px;
			left: 100%;
			margin-left: 12px;
			font-size: 13px;
			font-weight: normal;
			letter-spacing: 0.05em;
		}
	}

	&--white {

		.progress-bar {
			background: $color-light;
		}
	}
}

.video-popup {
  display: block;
  position: relative;

  img {
	display: block;
	height: auto;
	width: 100%;
  }

  .btn-center {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
  }

  .btn-play {

  }
}


.show-m {
  display: none;

  @media (max-width: 767px) {
	display: block;
  }
}

.hide-m {
  display: block;

  @media (max-width: 767px) {
	display: none;
  }
}

.lb-placeholder {
  height: 83px;

  @media (max-width: 767px) {
	height: 0;
  }
}

@media (max-width: 767px) {
  .intro-marketing {
	padding-top: 0;
  }
}

.author-block {
  clear: both;
  padding: 0 15px;
}