.ico-arrow-back-orange { background: url(../images/sprite/ico-arrow-back-orange.png) no-repeat 0 0; background-size: 100% 100%; width: 22px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-arrow-back { background: url(../images/sprite/ico-arrow-back.png) no-repeat 0 0; background-size: 100% 100%; width: 22px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook-blue { background: url(../images/sprite/ico-facebook-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook { background: url(../images/sprite/ico-facebook.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-googleplus-blue { background: url(../images/sprite/ico-googleplus-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-googleplus { background: url(../images/sprite/ico-googleplus.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram-blue { background: url(../images/sprite/ico-instagram-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram { background: url(../images/sprite/ico-instagram.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin-blue { background: url(../images/sprite/ico-linkedin-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 19px; height: 19px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin { background: url(../images/sprite/ico-linkedin.png) no-repeat 0 0; background-size: 100% 100%; width: 17px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pinterest-blue { background: url(../images/sprite/ico-pinterest-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 13px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-pinterest { background: url(../images/sprite/ico-pinterest.png) no-repeat 0 0; background-size: 100% 100%; width: 13px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter-blue { background: url(../images/sprite/ico-twitter-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 21px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter { background: url(../images/sprite/ico-twitter.png) no-repeat 0 0; background-size: 100% 100%; width: 21px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-vimeo-blue { background: url(../images/sprite/ico-vimeo-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-vimeo { background: url(../images/sprite/ico-vimeo.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube-blue { background: url(../images/sprite/ico-youtube-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 21px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube { background: url(../images/sprite/ico-youtube.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 21px; display: inline-block; vertical-align: middle; font-size: 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.ico-arrow-back-orange { background: url(../images/sprite/ico-arrow-back-orange@2x.png) no-repeat 0 0; width: 22px; height: 7px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-arrow-back { background: url(../images/sprite/ico-arrow-back@2x.png) no-repeat 0 0; width: 22px; height: 7px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook-blue { background: url(../images/sprite/ico-facebook-blue@2x.png) no-repeat 0 0; width: 18px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook { background: url(../images/sprite/ico-facebook@2x.png) no-repeat 0 0; width: 18px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-googleplus-blue { background: url(../images/sprite/ico-googleplus-blue@2x.png) no-repeat 0 0; width: 18px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-googleplus { background: url(../images/sprite/ico-googleplus@2x.png) no-repeat 0 0; width: 18px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram-blue { background: url(../images/sprite/ico-instagram-blue@2x.png) no-repeat 0 0; width: 18px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram { background: url(../images/sprite/ico-instagram@2x.png) no-repeat 0 0; width: 18px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin-blue { background: url(../images/sprite/ico-linkedin-blue@2x.png) no-repeat 0 0; width: 19px; height: 19px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin { background: url(../images/sprite/ico-linkedin@2x.png) no-repeat 0 0; width: 17px; height: 17px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-pinterest-blue { background: url(../images/sprite/ico-pinterest-blue@2x.png) no-repeat 0 0; width: 13px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-pinterest { background: url(../images/sprite/ico-pinterest@2x.png) no-repeat 0 0; width: 13px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter-blue { background: url(../images/sprite/ico-twitter-blue@2x.png) no-repeat 0 0; width: 21px; height: 17px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter { background: url(../images/sprite/ico-twitter@2x.png) no-repeat 0 0; width: 21px; height: 17px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-vimeo-blue { background: url(../images/sprite/ico-vimeo-blue@2x.png) no-repeat 0 0; width: 18px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-vimeo { background: url(../images/sprite/ico-vimeo@2x.png) no-repeat 0 0; width: 18px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube-blue { background: url(../images/sprite/ico-youtube-blue@2x.png) no-repeat 0 0; width: 18px; height: 21px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube { background: url(../images/sprite/ico-youtube@2x.png) no-repeat 0 0; width: 18px; height: 21px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }}
