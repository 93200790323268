/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs {

	.nav-tabs {
		display: flex;
		flex-wrap: wrap;
		border-left: 1px solid #eeeeee;
		border-bottom: none;
		margin-bottom: 0;
		list-style: none outside none;

		> li {
			display: flex;
			flex: 1 0 auto;
			float: none;
			margin-bottom: 0;

			&.active {

				> a {
					background: #eeeeee;
					color: $color-grey;

					&:before {
						height: 3px;
					}

					&:hover,
					&:focus {
						border-bottom: none;
						border-color: transparent;
					}
				}
			}

			> a {
				position: relative;
				flex: 0 1 100%;
				padding: 17px 10px;
				border: 1px solid #eeeeee;
				border-left-color: transparent;
				border-bottom: none;
				border-radius: 0;
				margin-right: 0;
				background: none;
				font-size: 12px;
				line-height: 1.3;
				color: $color-grey;
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				text-align: center;
				transition: background .4s, color .4s;

				&:before {
					position: absolute;
					bottom: 100%;
					left: -1px;
					right: -1px;
					height: 0;
					margin-bottom: 1px;
					background: $color-blue;
					content: '';
					transition: height .4s;
				}

				&:focus,
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.tab-content {
		background: #eeeeee;
	}

	.tab-body {
		padding: 62px 70px 63px;
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.tabs {

		.nav-tabs {

			> li {

				> a {
					padding-left: 3px;
					padding-right: 3px;
					font-size: 11px;
					letter-spacing: 0.03em;
				}
			}
		}

		.tab-body {
			padding: 30px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.tabs {

		.nav-tabs {
			display: block;

			> li {

				> a {

					&:before {
						left: -2px;
					}
				}
			}
		}

		.tab-body {
			padding: 20px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.tabs {

		.tab-body {
			padding: 15px;
		}
	}
}
