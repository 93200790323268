/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after {
	content: ''; 
	line-height: 0; 
	display: table; 
	clear: both; 
}


/*  Notext  */

.notext {
	white-space: nowrap; 
	text-indent: 100%; 
	text-shadow: none; 
	overflow: hidden; 
}
.notext-old {
	font-size: 0; 
	line-height: 0; 
	text-indent: -4000px; 
}


/*  Hidden  */

[hidden],
.hidden {
	display: none; 
}


/*  Alignleft  */

.alignleft {
	float: left; 
}


/*  Alignright  */

.alignright {
	float: right; 
}


/*  Disabled  */

[disabled],
.disabled {
	cursor: default; 
}


/*  Link  */
.link {
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	transition: color .4s;

	&:hover,
	&:focus {
		color: $color-orange;
		text-decoration: none;

		span {

			i {

				&:first-child {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}

		&:before {
			border-color: $color-orange;
		}
	}

	span {
		position: relative;
		display: inline-block;
		top: -1px;
		margin-right: 8px;
		font-size: 0;
		vertical-align: middle;

		i {
			transition: opacity .4s;

			&:nth-child(2) {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
	}

	&--small {
		font-size: 12px;
		letter-spacing: 0.07em;
	}

	&--medium {
		font-size: 13px;
	}

	&--orange {
		position: relative;
		display: inline-block;
		color: $color-orange;

		&:after {
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			border-bottom: 1px solid $color-orange;
			content: '';
			transition: left .4s, width .4s;
		}

		&:hover,
		&:focus {

			&:after {
				left: 0;
				width: 100%;
			}
		}
	}

	&--prev,
	&--next {

		&:before {
			position: absolute;
			top: 4px;
			left: 1px;
			@include chevron(9px, 2px, $color-grey, 0 0 0 0, 45deg, 50% 50%, .4s);
		}
	}

	&--prev {
		padding-left: 35px;
	}

	&--next {
		padding-right: 29px;

		&:before {
			left: auto;
			right: 1px;
			transform: rotate(-135deg);
		}
	}
}


/*  Grid  */

.cols {
	margin: 0 -15px; 
}
.cols:after {
	content: ''; 
	line-height: 0; 
	display: table; 
	clear: both; 
}

.col {
	float: left; 
	width: 100%; 
	padding: 0 15px; 
}

.col-1of2 {
	width: 50%; 
}



