/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	white-space: normal;
	cursor: pointer;
	appearance: none;
	transition: border .4s, background .4s, color .4s, opacity .4s, visibility .4s, transform .4s;
}

.btn {

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

/*  Btn Default  */
.btn-default {
	position: relative;
	min-width: 249px;
	padding: 14px 10px 13px;
	border: 2px solid $color-orange;
	border-radius: 0;
	background: $color-orange;
	font-size: 12px;
	color: $color-light;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.05em;

	&:active:hover,
	&:active:focus,
	&:active,
	&:hover,
	&:focus {
		border-color: $color-orange;
		background: $color-light;
		color: $color-orange;
		outline: none;
		box-shadow: none;

		&:before {
			border-color: $color-orange;
		}
	}

	&--medium {
		min-width: 198px;
	}

	&--small {
		min-width: 223px;
	}

	&--xsmall {
		min-width: 148px;
	}

	&--xxsmall {
		min-width: 150px;
		padding: 10px 15px 9px;
		font-size: 11px;
	}

	&--orange {
		
		&:active:hover,
		&:active:focus,
		&:active,
		&:hover,
		&:focus {
			border-color: $color-light;
		}
	}

	&--white {
		border-color: $color-light;
		background: $color-light;
		color: $color-blue;

		&:before {
			border-color: $color-blue;
		}

		&:active:hover,
		&:active:focus,
		&:active,
		&:hover,
		&:focus {
			border-color: $color-blue;
			background: $color-blue;
			color: $color-light;

			&:before {
				border-color: $color-light;
			}
		}
	}

	&--blue {
		border-color: $color-blue;
		background: $color-blue;
		color: $color-light;

		&:active:hover,
		&:active:focus,
		&:active,
		&:hover,
		&:focus {
			border-color: $color-light;
			background: $color-light;
			color: $color-blue;

			&:before {
				border-color: $color-blue;
			}
		}
	}

	&--arrow {
		padding-top: 13px;
		font-size: 13px;
		letter-spacing: 0.07em;

		&:active:hover,
		&:active:focus,
		&:active,
		&:hover,
		&:focus {
			border-color: transparent;
		}

		&:before {
			position: absolute;
			top: 50%;
			right: 26px;
			@include chevron(6px, 2px, $color-light, -4px 0 0 0, -135deg, 50% 50%, .4s);
		}
	}
}

/*  Btn Contacts  */
.btn-contacts {
	position: fixed;
	bottom: 0;
	right: 30px;
	display: none;
	padding-left: 25px;
	padding-right: 23px;
	letter-spacing: 0.075em;
}

/*  Btn Play  */
.btn-play {
	display: inline-block;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: $color-light;
	box-shadow: 0 0 0 15px rgba($color-light, 0.8);
	transition: box-shadow .4s;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8.5px 0 8.5px 12px;
		border-color: transparent transparent transparent $color-orange;
		margin: -13px 0 0 -6px;
		content: '';
	}

	&:hover {
		box-shadow: 0 0 0 19px rgba($color-light, 0.8);
	}
}

/*  Btn Close  */
.btn-close {
	position: relative;
	width: 60px;
    height: 60px;
    padding: 0;
    border-radius: 50%;
    border: 1px solid #ddd;
    background: #ffffff;
    font-size: 0;
    opacity: 1;

	&:before,
	&:after {
		position: absolute;
	    top: 18px;
	    left: 28px;
	    width: 2px;
	    height: 21px;
	    background: #888888;
	    content: '';
	    transition: background .4s;
	}

	&:before {
		transform: rotate(-45deg);
	}

	&:after {
		transform: rotate(45deg);
	}

	&:hover {
		background: #ddd;
		transform: rotate(90deg);

		&:before,
		&:after {
			background: $color-light;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.btn-play {
		width: 60px;
		height: 60px;
		box-shadow: 0 0 0 10px rgba($color-light, 0.8);

		&:hover {
			box-shadow: 0 0 0 14px rgba($color-light, 0.8);
		}
	}

	.btn-close {
		width: 50px;
		height: 50px;

		&:before,
		&:after {
			top: 14px;
			left: 23px;
			height: 18px;
		}
	}
}
