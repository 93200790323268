/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

/* Mobile */

@include breakpoint-mobile {
	.container-fluid,
	.container {
		padding: 0 32px;
	}
}

/* Mobile */

@include breakpoint-mobile-portrait {
	.container,
	.container-fluid {
		padding: 0 15px;
	}
}
