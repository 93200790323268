/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {

	tr {

		&:nth-child(odd) {

			td {
				background: $color-blue;
				color: $color-light
			}
		}
	}

	td {
		padding: 15px 0 14px 28px;
		background: #eeeeee;
		font-size: 12px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		&:first-child {
			width: 170px;
		}

		&:nth-child(2) {
			width: 101px;
		}

		&:nth-child(3) {
			width: 201px;
		}

		&:nth-child(4) {
			width: 98px;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.table {

		td {
			padding-left: 10px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Table Reports
\* ------------------------------------------------------------ */

.table-reports {
	border-bottom: 1px solid #dddddd;
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}

	th {
		padding: 15px 0 16px 30px;
		background: $color-blue;
		color: $color-light;
		font-size: 12px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: left;

		&:first-child {
			width: 370px;
		}

		&:nth-child(2) {
			width: 300px;
		}

		&:nth-child(3) {
			width: 360px;
		}
	}

	td {
		padding: 21px 31px 20px 29px;
		vertical-align: top;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.table-reports {

		table,
		thead,
		tbody,
		tr,
		th,
		td {
			display: block;
			width: 100%;
		}

		th {
			display: none;
		}

		td {
			padding: 15px;

			&:before {
				display: block;
				padding: 15px;
				margin: -15px -15px 15px;
				background: $color-blue;
				color: $color-light;
				font-size: 12px;
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				text-align: left;
				content:attr(data-title);
			}
		}
	}
}
