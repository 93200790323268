/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {

	> span,
	> small {
		display: block;
	}

	> span {
		margin-bottom: 4px;
		font-size: 13px;
		color: $color-blue;
		font-weight: normal;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	> small {
		font-size: 14px;
	}

	blockquote {
		position: relative;
		min-height: 253px;
		padding: 65px 0 0;
		border: none;
		margin-bottom: 10px;

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			width: 25px;
			height: 22px;
			margin-left: -13px;
			background: url(../images/quotes@2x.png) no-repeat 0 0;
			background-size: 100% 100%;
			content: '';
		}

		p {
			font-size: 25px;
			line-height: 1.4;
			font-style: italic;
		}
	}

	&--large {

		//blockquote {
		//	min-height: 272px;
        //
		//	p {
		//		font-size: 30px;
		//		line-height: 1.35;
		//	}
		//}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.testimonial {

		&--large {

			blockquote {

				p {
					font-size: 25px;
					line-height: 1.4;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.testimonial {
		overflow: hidden;

		blockquote {
			min-height: 0;
			padding-top: 62px;
			margin-bottom: 30px;

			&:before {
				margin-left: -17px;
			}

			p {
				font-size: 16px;
				line-height: 1.35;
			}
		}
	}
}
