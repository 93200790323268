/* ------------------------------------------------------------ *\
	Map Locations
\* ------------------------------------------------------------ */

.map-locations {
	height: 650px;
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.map-locations {
		height: 600px;
	}
}

/* Mobile */

@include breakpoint-mobile {
	.map-locations {
		height: 350px;
	}
}

/* ------------------------------------------------------------ *\
	Map Popup
\* ------------------------------------------------------------ */

.map-popup-outer {
	position: relative;
	margin-left: -15px;
	margin-top: -30px;
	transform: translateY(-100%);

	> img {
		position: absolute !important;
		top: 0;
		right: 0;
		z-index: 5;
		width: 30px;
		height: 30px;
		opacity: 0;

		&:hover ~ .map-popup {

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(135deg);
			}
		}
	}

	&:before {
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background: $color-light;
		opacity: .8;
		content: '';
	}
}

.map-popup {
	width: 225px;
	background: $color-blue;
	font-family: $font-montserrat;
	color: $color-light;
	text-align: center;

	img {
		width: 100%;
	}

	&:before,
	&:after {
		position: absolute;
		top: 10px;
		right: 14px;
		width: 1px;
		height: 11px;
		background: $color-blue;
		content: '';
		transition: transform .4s;
	}

	&:before {
		transform: rotate(-45deg);
	}

	&:after {
		transform: rotate(45deg);
	}
}

.map-popup-content {
	padding: 16px 15px 17px;

	color: #fff !important;
	text-decoration: none !important;

	h3,
	h4 {
		text-transform: none;
	}

	h3 {
		margin-bottom: 5px;
		font-size: 15px;
		line-height: 1.35;
		font-weight: normal;
	}

	h4 {
		font-size: 13px;
		font-weight: 300;
	}
}

/* Mobile */

@include breakpoint-mobile {
	/*  Map Popup  */
	.map-popup-outer {
		margin: 0;
		transform: translate(-50%, -50%);
	}
}
