/* ------------------------------------------------------------ *\
	Pagination
\* ------------------------------------------------------------ */

.pagination {
	margin: 0 -15px;
	list-style: none outside none;

	li {
		display: inline-block;
		vertical-align: top;
	}

	> li {

		&.active {

			a {
				margin-left: 0;
				margin-right: 0;
				background: $color-blue;
				color: $color-light;

				&:hover,
				&:focus {
					background: $color-blue;
					color: $color-light;
				}
			}
		}

		> a {
			float: none;
			display: block;
			width: 30px;
			height: 30px;
			padding: 0;
			border-radius: 50%;
			border: none;
			margin: 0 -2px;
			background: none;
			font-size: 12px;
			line-height: 30px;
			color: $color-grey;
			font-weight: normal;
			letter-spacing: 0.05em;
			text-align: center;
			transition: color .4s;

			&:focus,
			&:hover {
				background: none;
				color: $color-blue;

				&:before {
					border-color: $color-blue;
				}
			}

			&:before {
				position: relative;
				top: -2px;
				@include chevron(7px, 2px, $color-grey, 0 0 0 0, 45deg, 50% 50%, .4s);
				display: none;
			}
		}

		> .pagination {

			&__prev,
			&__next {
				margin: 0;

				&:before {
					display: inline-block;
				}
			}

			&__prev {
				margin-right: 13px;
			}

			&__next {
				margin-left: 13px;

				&:before {
					transform: rotate(-135deg);
				}
			}
		}
	}

}
