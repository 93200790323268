/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	overflow: hidden;
	display: inline-block;
	width: 182px;
	height: 42px;
	background: url(../images/marcusandmill2x.png) no-repeat 0 0;
	background-size: 100% 100%;
	font-size: 0;
	text-indent: 100%;
	white-space: nowrap;
}
