/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	background: $color-light;
	color: $color-darkgrey;
	text-align: center;

	.footer {

		&__inner {
			padding: 51px 0 60px;
		}

		&__content,
		&__bar {
		}

		&__content {
			padding: 53px 0 47px;
			
			img {
				margin-bottom: 16px;
			}

			p {
				font-size: 12px;
				line-height: 1.8;
			}
		}

		&__bar {
			padding: 15px 0 17px;
			text-align: left;

			p {
				margin-bottom: 0;
				font-size: 12px;
				color: #668591;

				a {
					transition: color .4s;

					&:hover,
					&:focus {
						color: $color-orange;
						text-decoration: none;
					}
				}
			}

			&-body {
				display: flex;
				justify-content: space-between;
				padding: 0 9px;
			}
		}

		&-nav {
			margin-bottom: 23px;

			ul {
				list-style: none outside none;
			}

			li {
				display: inline-block;
				margin: 0 22px;
				vertical-align: top;
			}

			a {
				font-size: 13px;
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				transition: color .4s;

				&:hover,
				&:focus {
					color: $color-orange;
					text-decoration: none;
				}
			}
		}

		&-nav-utilities {
			margin-bottom: 30px;

			ul {
				list-style: none outside none;
			}

			li {
				display: inline-block;
				margin: 0 8px;
				vertical-align: top;
			}

			a {
				font-size: 14px;
				transition: color .4s;

				&:hover,
				&:focus {
					color: $color-orange;
					text-decoration: none;
				}
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.footer {

		.footer {

			&__inner {
				padding-top: 53px;
				padding-bottom: 53px;
			}

			&-nav {

				li {
					display: block;
					margin: 0;
				}

				a {
					display: block;
					padding: 4px 0;
					font-size: 14px;
				}
			}

			&-nav-utilities {
				margin-bottom: 33px;
			}

			&__bar {
				padding-bottom: 23px;
				text-align: center;

				&-body {
					display: block;

					p {
						margin-bottom: 13px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
