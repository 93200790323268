/* ------------------------------------------------------------ *\
	Info Items
\* ------------------------------------------------------------ */

.info-items {
	counter-reset: numbers;

	&:last-child {
		margin-bottom: -20px;
	}

	.info-item {
		padding: 0 20px;

		&:before {
			display: inline-block;
			padding-bottom: 2px;
			border-bottom: 2px solid $color-orange;
			margin-bottom: 27px;
			font-size: 14px;
			color: $color-orange;
			font-weight: normal;
			counter-increment: numbers;
			content: counter(numbers, decimal-leading-zero);
		}
	}
}

.info-item {
	position: relative;
	margin-bottom: 20px;

	h5 {
		color: $color-lightblue;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.info-items {

		.info-item {
			padding: 0;
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.info-items {

		&:last-child {
			margin-bottom: -37px;
		}
	}
	
	.info-item {
		margin-bottom: 37px;

		h5 {
			font-weight: normal;
		}
	}
}
