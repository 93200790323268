/* ------------------------------------------------------------ *\
	Outro
\* ------------------------------------------------------------ */

.outro {
	padding: 43px 0;
	text-align: center;

	.outro {

		&__body {
			margin: 0 -25px;

			p {
				font-size: 12px;
				line-height: 1.85;
			}
		}
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.outro {

		.outro {

			&__body {
				margin: 0;
			}
		}
	}
}
