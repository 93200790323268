/* ------------------------------------------------------------ *\
	Services
\* ------------------------------------------------------------ */

.services {

	&:after {
		content: ''; 
		line-height: 0; 
		display: table; 
		clear: both; 
	}

	.service {
		float: left;
		width: 33.33%;

		&--large {
			float: right;
		}

		&--wide {
			width: 66.66%;
		}
	}
}

.service {
	position: relative;
	overflow: hidden;
	padding-top: 21.9%;
	background: $color-blue;
	color: $color-light;

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;

			.service {

				&__bg {
					transform: scale(1.1);
				}
			}
		}
	}

	.service {

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: transform .6s;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $color-dark;
				opacity: .4;
				content: '';
			}
		}

		&__content {
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 2;
			width: 100%;
			padding: 0 5px;
			text-align: center;
			transform: translateY(-50%);

			h2 {
				position: relative;
				padding: 22px 0 7px;

				&:before {
					position: absolute;
					top: 0;
					left: 50%;
					width: 40px;
					height: 2px;
					margin-left: -20px;
					background: $color-orange;
					content: '';
				}
			}

			h4 {
				display: inline-block;
				min-width: 222px;
				padding: 13px 33px;
				border: 1px solid $color-light;
				text-transform: uppercase;
			}
		}
	}

	&--large {
		padding-top: 43.8%;
	}
}

/* Small Desktop */

@include breakpoint-small-desktop {
	.service {

		.service {

			&__content {

				h4 {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}

/* Tablet Portrait */

@include breakpoint-tablet-portrait {
	.services {

		.service {
			width: 50%;

			&--wide {
				width: 100%;
			}

			&--alt {
				width: 100%;
			}
		}
	}
}

/* Mobile */

@include breakpoint-mobile {
	.services {
		display: flex;
		flex-wrap: wrap;

		.service {
			flex: 0 1 100%;
			float: none;
			width: auto;

			&:first-child {
				order: -1;
			}

			&:nth-child(4) {
				order: 2;
			}

			&:nth-child(6) {
				order: 3;
			}

			&:nth-child(2) {
				order: 4;
			}

			&:nth-child(5) {
				order: 5;
			}

			&:nth-child(3) {
				order: 6;
			}

			&:nth-child(7) {
				order: 7;
			}

		}
	}

	.service {
		padding-top: 66%;

		.service {

			&__content {
				padding: 0 10px;

				h2 {
					margin-top: 5px;

					br {
						display: none;
					}
				}

				h4 {
					min-width: 268px;
					padding: 14px 20px;
					line-height: 1.2;
					letter-spacing: 0.05em;

					br {
						display: none;
					}
				}
			}
		}
	}
}

.places {
  padding: 0 20px;
}